<template>
  <Page>
    <div class="flex flex-col items-center justify-center h-screen">
      <div class="w-full bg-white rounded-lg shadow sm:max-w-md">
        <div class="p-6 space-y-4">
          <img src="@assets/logo_name.svg" alt="Suomalainen Kirjakauppa" class="w-full max-w-[240px] block mx-auto mb-14">
          <RouterView v-slot="{Component}">
            <Transition name="fade" mode="out-in">
              <component :is="Component" />
            </Transition>
          </RouterView>
        </div>
      </div>
    </div>
  </Page>
</template>


<script setup lang="ts">
import Page from "@/components/Page.vue";
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.15s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
