<template>
  <div>
    <h1 class="text-xl font-bold text-center text-gray-900 md:text-2xl mb-4">Käyttäjätilin poisto</h1>
    <p class="text-gray-700 text-center text-sm">
      Oletko varma, että haluat poistaa tilisi? Poistettuja tilejä ei voida palauttaa.
    </p>

    <label class="mt-4 confirmation-label flex items-center select-none cursor-pointer">
      <input type="checkbox" v-model="confirmed" />
      <div class="checkbox-indicator">
        <span class="checkbox-indicator-inner" />
      </div>
      <span class="text-base text-gray-700">Olen varma, että haluan poistaa käyttäjätilini.</span>
    </label>

    <AppButton :disabled="!confirmed" type="button"
      @click="confirmDeletion"
      class="w-full bg-skk-red text-white mt-8">
      Poista käyttäjätili
    </AppButton>
  </div>
</template>


<script setup lang="ts">
import {ref} from "vue";
import {useRouter} from "vue-router";
import AppButton from "@/components/Button.vue";
import {client} from "@/apiClient";
import * as dialog from "@/dialog";

const router = useRouter();
const confirmed = ref(false);

const confirmDeletion = async () => {
  dialog.loader();
  try {
    await client.user.deleteAccount.mutate();
    dialog.alert("Tili poistettu", "Käyttäjätilisi on poistettu onnistuneesti.");
    router.replace("/poista-tili");
  } catch (e) {
    dialog.closeDialog();
    dialog.alert("Virhe", "Tilin poistaminen epäonnistui. Yritä myöhemmin uudelleen.");
  }
};

</script>

<style scoped>

.confirmation-label > .checkbox-indicator {
  content: "";
  @apply inline-block w-5 h-5 mr-2 border-2 border-black rounded-sm bg-white;
  @apply relative;
}

.confirmation-label .checkbox-indicator-inner {
  @apply absolute top-2/4 left-2/4 w-3/4 h-3/4;
  @apply rounded-sm -translate-y-1/2 -translate-x-1/2;
  @apply transition-all;
}

.confirmation-label > input[type="checkbox"]:checked + .checkbox-indicator > .checkbox-indicator-inner {
  @apply bg-black;
}

.confirmation-label > input {
  @apply hidden;
}

</style>
